import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export const useApiClient = () => {
  const { getAccessTokenSilently } = useAuth0();

  axios.interceptors.request.use(async (config) => {
    switch (process.env.REACT_APP_ENV) {
      case "local":
        config.baseURL = "http://localhost:3030/api/v1";
        break;
      case "staging":
        config.baseURL =
          "https://wayfinder-platform-api-staging.azurewebsites.net/api/v1";
        break;
      case "release":
        config.baseURL =
          "https://wayfinder-platform-api-release.azurewebsites.net/api/v1";
        break;
      case "production":
        config.baseURL =
          "https://wayfinder-platform-api.azurewebsites.net/api/v1";
        break;
      default:
        config.baseURL =
          "https://wayfinder-platform-api-staging.azurewebsites.net/api/v1";
    }

    // TODO: make axios config typesafe
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers = {
      "Content-type": "application/json",
    };

    if (typeof config.headers.Authorization === "undefined") {
      config.headers.Authorization = `Bearer ${await getAccessTokenSilently()}`;
    }
    return config;
  });

  return axios;
};
