import React from "react";
import { styled } from "@mui/system";
import { QuestionMarkOutlined } from "@mui/icons-material";
import { UnstyledButton, type UnstyledButtonProps } from "./UnstyledButton";

const StyledHelpButton = styled(UnstyledButton)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  height: "40px",
  width: "40px",
  color: theme.palette.v2.neutral[20],
  backgroundColor: theme.palette.v2.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.v2.primary.hover,
  },
  "> svg": {
    fontSize: "20px",
  },
}));

export const HelpButton: React.FC<UnstyledButtonProps> = (props) => {
  return (
    <StyledHelpButton {...props}>
      <QuestionMarkOutlined />
    </StyledHelpButton>
  );
};
