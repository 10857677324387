import * as React from "react";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import type { TDocumentsResponseDTO } from "../types";
import { QUERY_KEYS } from "_global/constants";

type UseGetDocumentsOptions = {
  enabled?: boolean;
};

type UseGetDocumentQueryState = UseQueryResult<TDocumentsResponseDTO, Error>;

export const useGetDocuments = (options?: UseGetDocumentsOptions): UseGetDocumentQueryState => {
  const { enabled = true } = options || {};
  const { showErrorToast } = useToastRenderer();

  const client = useApiClient();

  const queryState = useQuery<TDocumentsResponseDTO, Error>({
    enabled,
    queryKey: [QUERY_KEYS, "all"],
    queryFn: async () => {
      const response = await client.get<TDocumentsResponseDTO>("/documents");
      return response.data;
    },
  });

  const { isError } = queryState;

  React.useEffect(() => {
    if (queryState.isError) showErrorToast({ id: "err-fetch-dcx", message: "Error fetching documents" });
  }, [isError]);

  return queryState;
};
