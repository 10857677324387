import { Box, styled } from "@mui/system";
import { Link } from "react-router-dom";
import { ThemeButton as Button } from "_global/components/Buttons/ThemeButton";
import { HelpButton } from "_global/components/Buttons/HelpButton";
import { routes } from "features/Navigation/util/routes";

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "12px",
  padding: "24px",
  width: "100%",
  maxWidth: "100%",
  [theme.breakpoints.down("md")]: {
    padding: "16px",
    gap: "4px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "16px 8px",
  },
}));

const StyledHeaderRight = styled(Box)(({ theme }) => ({
  margin: "0 -8px 0 8px",
  padding: "16px",
  alignSelf: "stretch",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: theme.palette.v2.neutral[20],
  borderRadius: "16px",
  gap: "16px",
  [theme.breakpoints.up("lg")]: {
    maxWidth: "70%",
  },
}));

export const HeaderRightDesktop = styled(StyledHeaderRight)(({ theme }) => ({
  minWidth: "0px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const HeaderRightMobile = styled(StyledHeaderRight)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "inline-flex",
    margin: "0px",
    gap: "8px",
    padding: "16px",
    flexGrow: 0,
    alignSelf: "unset",
  },
}));

type ViewSavedDesignsButtonProps = {
  disabled?: boolean;
  isDrawer?: boolean;
};

// Appears in Bottom Drawer and Header
export const ViewSavedDesignsButton: React.FC<ViewSavedDesignsButtonProps> = ({ disabled = false, isDrawer = false }) => {
  return (
    <Link to={routes.designStudio.path} target="_blank" aria-label="Go to saved designs page" style={{ width: isDrawer ? "100%" : "min-content" }}>
      <Button fullWidth={isDrawer} size={isDrawer ? "large" : "small"} light tabIndex={-1} label="View Saved Designs" disabled={disabled} />
    </Link>
  );
};

export const StudioHeaderHelpButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box
      sx={{
        margin: { xs: "0 0 0 auto", sm: "0 -8px 0 8px" },
        padding: "16px",
        backgroundColor: theme => theme.palette.v2.neutral[20],
        borderRadius: "16px",
      }}      
    >
      <HelpButton
        sx={{
            height: "28px",
            width: "28px",
            "> svg": {
                fontSize: "16px",
            },
        }}
        onClick={onClick}
        aria-label="View more information"
        title="View more information"
      />
    </Box>
  );
};
