import { DEFAULT_CONFIG_ID } from "../constants";

export function logResultErrors(label: string, result: VR360Result, debugMode = false) {
  if (debugMode) console.group(`${"🔹"} ${label}`);
  if (!result.success) {
    console.warn(label, "Error observed in VR360Result:", result);
  } else if (result.messageId.startsWith("E_")) {
    console.warn(label, "Potential error observed in VR360Result:", result);
  } else if (!result.isReady) {
    console.warn(label, "VR360Result is not ready:", result);
  } else {
    if (debugMode) console.log("VR360Result ok ✅:", result);
  }
  if (debugMode) console.groupEnd();
};

export const getVR360IframeUrl = (configId?: string, isAuthed?: boolean) => `${process.env.REACT_APP_ZONDA_360_URL}${configId || DEFAULT_CONFIG_ID}${isAuthed ? "&authed=true" : ""}`;

const isValidConfigId = (configId: unknown) => (
  typeof configId === "string"
  && configId.length > 0
  && configId.toLowerCase() !== "success" // VR360Result.message is used for both configId and other messages, ironically "success" is invalid, configIds are long encoded strings
);

export const handleGetConfigIdResults = (result: VR360Result, debugMode = false): { configId: string, isInvalid: boolean } => {
  /* ERROR HANDLING - not sure what errors to expect or if there's significant errors worth handling in this method */
  logResultErrors("getConfigId", result, debugMode);
  if (!isValidConfigId(result.message)) {
    console.warn("getConfigId", "Invalid configId:", result);
    return { configId: "", isInvalid: true };
  }
  return { configId: result.message, isInvalid: false };
};
