import * as React from "react";
import { Box, styled } from "@mui/system";
import { Button as BaseButton, type ButtonProps as BaseButtonProps } from "@mui/base";
import { Edit as EditIcon } from "@mui/icons-material";
import { EditDesignNameModal } from "../EditDesignName/EditDesignNameModal";
import { themeFonts } from "_global/theme/typography";
import { formatDateTimeDisplay } from "../utils";
import type { VR360ComponentProps } from "features/DesignStudio/VR360IframeApp";

export const SavedDesignTitle: React.FC<VR360ComponentProps> = ({
  savedDesign,
  onUpdateDesignName,
  isLoading,
}) => {
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);

  const openEditModal = () => setShowEditModal(true);
  const closeEditModal = () => setShowEditModal(false);

  if (!savedDesign?.name) return <StyledContainer />; // StyledContainer is a flex container that takes up the same space as the title

  return (
    <>
      <StyledContainer>
        <EditButton onClick={openEditModal}  />
        <StyledTitle>
          {savedDesign.name} | <StyledDate>Last saved: {formatDateTimeDisplay(savedDesign.updated_at)}</StyledDate>
        </StyledTitle>
      </StyledContainer>
      <EditDesignNameModal
        open={showEditModal}
        onClose={closeEditModal}
        onSave={(name: string) => {
          onUpdateDesignName(name, { onSuccess: closeEditModal });
        }}
        savedDesign={savedDesign}
        isLoading={isLoading}
      />
    </>
  );
};

const StyledContainer = styled(Box)(({theme}) => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  flexGrow: 1,
  justifySelf: "flex-start",
  paddingRight: theme.spacing(1),
  maxWidth: "calc(100% - 330px)", // 330px is roughly the width of the SavedDesign buttons in the same container
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "block",
  alignItems: "center",
  gap: "4px",
  ...themeFonts.textMdSemibold,
  letterSpacing: "0.25px",
  color: theme.palette.black,
  overflow: "hidden",
  textOverflow: "ellipsis",
  minWidth: "0px",
  whiteSpace: "nowrap",
}));

const StyledDate = styled("span")(() => ({
  ...themeFonts.textSmSemibold,
}));

const StyledEditButton = styled(BaseButton)(({ theme }) => ({
  color: theme.palette.v2.neutral[90],
  backgroundColor: "transparent",
  border: `0.5px solid ${theme.palette.v2.neutral[30]}`,
  cursor: "pointer",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "26px",
  width: "26px",
  transition: "all 0.05s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.v2.primary.surface,
    border: `0.5px solid ${theme.palette.v2.primary.border}`,
    color: theme.palette.v2.primary.main,
    boxShadow: theme.palette.v2.shadow1,
  },
  "svg": {
    fontSize: "18px",
  },
}));

const EditButton: React.FC<BaseButtonProps> = (props) => (
  <StyledEditButton
    aria-label="Edit saved design"
    {...props}
  >
    <EditIcon fontSize="inherit" />
  </StyledEditButton>
);
