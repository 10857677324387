import React from "react";
import { Box, Stack, styled } from "@mui/system";
import { useWindowResize } from "_global/hooks/useWindowResize";
import { BottomDrawerModal } from "_global/components/Drawers/BottomDrawer";
import { RightDrawerModal } from "_global/components/Drawers/RightDrawer/RightDrawer";
import { themeFonts } from "_global/theme/typography";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const StudioHelpDrawer: React.FC<Props> = (props) => {
  const { open, onClose } = props;
  const { windowSize, isTablet, isDesktop } = useWindowResize();
  const isMobile = !isTablet && !isDesktop;

  // Modal renders as a BottomDrawer on mobile, and a RightDrawer on tablet/desktop
  const DrawerModal = React.useMemo(() => (
    isMobile ? BottomDrawerModal : RightDrawerModal
  ), [isMobile]);
  
  if (!windowSize.width) return null; // prevent mounting before windowSize is available

  return (
    <DrawerModal
      id="studio-help-info-drawer"
      title="Help Bar"
      open={open}
      onClose={onClose}
    >
      <Stack gap="20px" pt="10px" pb="40px">
        <StyledTitle component="h4">Wayfinder Design Studio</StyledTitle>
        <Stack gap="0px" sx={{ maxWidth: { xs: "100%", sm: "420px" }}}>
          <StyledDescription component="p">{HELP_CONTENT_PARAGRAPHS[0]}</StyledDescription>
          <StyledDescription component="p">{HELP_CONTENT_PARAGRAPHS[1]}</StyledDescription>
          <StyledDescription component="p">{HELP_CONTENT_PARAGRAPHS[2]}</StyledDescription>
        </Stack>
      </Stack>
    </DrawerModal>
  );
};

const StyledTitle = styled(Box)(({ theme }) => ({
  margin: 0,
  color: theme.palette.black,
  ...themeFonts.textLgMedium,
}));

const StyledDescription = styled(Box)(({ theme }) => ({
  color: theme.palette.black,
  ...themeFonts.textLReading,
  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
    lineHeight: "24px",
  },
}));

const HELP_CONTENT_PARAGRAPHS = [
  "At Pahlisch, we want to ensure your home is exactly to your standards. Through the Wayfinder Design Studio, you can get an idea of your home's style with only a few clicks.",
  "Whether you're looking for a more modern interior, a stone exterior, or a traditional floor plan, use this sample home to determine what combination of selections will work for you. Play around with your options, or choose your specific selections and upgrades in preparation for your Design appointment.",
  "Note that the Design Studio is meant for inspiration, not for building your exact home. Look forward soon to even more floor plans available in future updates to the Design Studio.",
];

