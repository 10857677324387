import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { DocumentsController, type TDocumentsViewProps } from "./controllers/DocumentsController";
import { DocumentsDataGrid } from "./components/DocumentsDataGrid";
import { Tile } from "_global/components/Containers";
import { Typography } from "_global/components/Typography/Typography";
import { isDocumentsDataEmpty } from "./utils";
import { Stack } from "@mui/system";
import { SearchBar, FilterButtonGroup } from "./DocumentsListing.elements";
import { useDocumentFilters } from "./hooks/useDocumentFilters";

const DocumentsListWithFilters = ({ documentsData }: { documentsData: TDocumentsViewProps["documentsData"] }) => {
  const {
    clearFilters,
    searchQuery,
    setSearchQuery,
    selectedCategory,
    setSelectedCategory,
    filteredDocuments
  } = useDocumentFilters({ documentsData });

  return (
    <Stack spacing={2}>
      <SearchBar
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <FilterButtonGroup selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
      <DocumentsDataGrid documents={filteredDocuments} clearFilters={clearFilters} />
    </Stack>
  );
};


const DocumentsListRenderer = ({ documentsData, isLoading, isError }: TDocumentsViewProps) => {
  const shouldShowEmptyState = isDocumentsDataEmpty(documentsData);

  // Loading State
  if (isLoading) return (
    <Tile variant="lightTile" alignItems="center" justifyContent="center" sx={{ height: "250px", color: "#C9C9C9" }}>
      <CircularProgress color="inherit" />
    </Tile>
  );

  // Empty State / & Empty Error State
  if (shouldShowEmptyState) return (
    <Typography.Paragraph>
      {isError ? "We were unable to load your documents at this time." : "No documents found."}
    </Typography.Paragraph>
  );

  // Populated State
  return <DocumentsListWithFilters documentsData={documentsData} />;
};

export const DocumentsListing = () => {
  const { user } = useGlobalContext();
  if (!user) return null;

  return (
    <DocumentsController component={DocumentsListRenderer} />
  );
};
