import { Typography } from "_global/components/Typography/Typography";
import { Stack, styled } from "@mui/system";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { DOCUMENT_CATEGORIES_LIST } from "./constants";
import type { TDocumentFilterCategory } from "./types";


const StyledSearchBar = styled("input")(({ theme }) => ({
  width: "100%",
  padding: "10px 16px",
  borderRadius: "50px",
  border: "1px solid #C9C9C9",
  marginBottom: "10px",
  minHeight: "40px",
  maxWidth: "500px",
  outline: "1px solid transparent",
  transition: "border-color 0.075s ease-in-out",
  "&:focus": {
    borderColor: theme.palette.v2.primary.main,
  },
}));

export const SearchBar = (props: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string
}) => {
  return (
    <StyledSearchBar
      type="search"
      placeholder="Search for..."
      {...props}
    />
  );
};

const FilterButton = ({ label, onClick, showBorder }: { label: string; onClick: () => void; showBorder: boolean }) => (
  <PrimaryButton
    light
    size="medium"
    label={label}
    onClick={onClick}
    showBorder={showBorder}
  />
);

export const FilterButtonGroup = ({
  selectedCategory,
  setSelectedCategory,
}: {
  selectedCategory: TDocumentFilterCategory | null;
  setSelectedCategory: (Category: TDocumentFilterCategory | null) => void;
}) => (
  <Stack direction={{ xs: "column", md: "row" }} gap={3} alignItems={{ xs: "start", md: "center" }}>
    <Typography.H4
      sx={{ fontVariant: "all-small-caps", lineHeight: 0, display: "inline-flex", marginBottom: "4px", fontSize: "22px" }}
      component="div"
    >
      Filter by Category
    </Typography.H4>
    <Stack direction="row" gap={1}>
      <FilterButton
        label="All"
        onClick={() => setSelectedCategory(null)}
        showBorder={selectedCategory === null}
      />
      {DOCUMENT_CATEGORIES_LIST.map(([category, label]) => (
        <FilterButton
          key={category}
          label={label}
          onClick={() => setSelectedCategory(category)}
          showBorder={category === selectedCategory}
        />
      ))}
    </Stack>
  </Stack>
);
