import * as React from "react";
import { Box } from "@mui/system";
import { Brand } from "./Brand";
import { SavedDesignTitle } from "./SavedDesignTitle";
import { SavedDesignMenuButton } from "./SavedDesignMenuButton";
import {
  HeaderContainer,
  HeaderRightDesktop,
  HeaderRightMobile,
  StudioHeaderHelpButton,
  ViewSavedDesignsButton,
} from "./StudioHeader.elements";
import { ExpandedOptionsButton } from "./ExpandedOptionsButton";
import type { VR360ComponentProps } from "features/DesignStudio/VR360IframeApp";

type Props = VR360ComponentProps & {
    onClickHelpButton: () => void;
};

export const StudioHeader: React.FC<Props> = ({
    onClickHelpButton,
    ...props
}) => {
    const { isAuthed } = props;

    return (
        <HeaderContainer component="header">
            <Brand />
            <HeaderRightDesktop>
                <SavedDesignTitle {...props} />
                <Box display="inline-flex" flexShrink={0} alignItems="center" gap="16px" sx={{ minWidth: "0px" }}>
                    <SavedDesignMenuButton {...props} />
                    { isAuthed ? <ViewSavedDesignsButton /> : <ViewSavedDesignsButton disabled={true} />}
                </Box>
            </HeaderRightDesktop>
            <HeaderRightMobile>
                <ExpandedOptionsButton {...props} />
            </HeaderRightMobile>
            <StudioHeaderHelpButton onClick={onClickHelpButton} />
        </HeaderContainer>
    );
};
